import { curry } from 'ramda';

export const ROOT_PATH = '/';
export const LEAGUE_PATH = '/league/:league';

export const MY_GAMES = '/my_games';
export const MY_GAMES_LEAGUE_PATH = '/my_games/league/:league';

export const MY_GAMES_ACTIVE = '/my_games/active';
export const MY_GAMES_RECENT = '/my_games/recent';

export const GAMES_COUNT = '/home/count';
// export const GAMES_COUNT = '/count';
export const GET_MEDIA = '/get_media';
export const REFS_PATH = '/refs';
export const CONTEST_JOIN_PATH = '/contests/:id/join_user';

// export const AUTH_PATH = '/auth';
export const FEEDBACK_PATH = '/feedbacks';
export const AUTH_PATH = '/auth';
export const SIGN_UP_PATH = '/auth/sign_up';
export const SIGN_UP_REFERAL_PATH = '/auth/sign_up/:referalCode';
export const SIGN_IN_PATH = '/auth/sign_in';
export const AUTH_VIA_FACEBOOK = '/auth/facebook';
export const SETUP_ACCOUNT_PATH = '/auth/setup_account';
export const SIGN_UP_REFERAL_CODE_PATH = '/auth/sign_up/:referal_code';
export const RESTORE_PASSWORD_PATH = '/auth/restore_password';
export const RESTORE_PASSWORD_FROM_EMAIL_PATH = '/auth/:id/restore_password/:token';
export const CHANGE_PASSWORD_VIA_EMAIL_PATH = '/auth/:id/change_password_via_email';

export const LEADERBOARD_PATH = '/leaderboard';

export const NOTIFICATIONS_PATH = '/notifications';
export const DISMISS_NOTIFICATION_PATH = '/notifications/:id/dismiss';
// export const SIGN_IN_PATH = '/auth/sign_in';

export const ACCOUNT_PATH = '/account';
export const MY_ACCOUNT_PATH = '/me';
// export const ACCOUNT_SETUP_PATH = '/account/setup';
export const ACCOUNT_SETTINGS_PATH = '/account/settings';
export const ACCOUNT_EMAIL_PATH = '/account_email';
export const ACCOUNT_MY_PROGRESS_PATH = '/account/my_progress';
export const ACCOUNT_INVITE_FRIENDS_PATH = '/account/invite_friends';

export const SIGN_UP = '/auth/sign_up';
export const SIGN_IN = '/auth/sign_in';
export const SIGN_OUT = '/auth/sign_out';

export const FAVOURITE_TEAMS = '/account/favourite_teams';

export const DISMISS_ALL_ACTIVE = 'notifications/dismiss_all_active';

export const ACCOUNT = '/account';

export const BLOG_PATH = '/blog';
export const BLOG_CATEGORIES_PATH = '/blog/categories';
export const BLOG_ARTICLE_PATH = '/blog/posts/:articleId';
export const BLOG_ARTICLES_PATH = '/blog/posts';
export const BLOG_CATEGORY_PATH = '/blog/categories/:categoryId';

export const CONTESTS_AVAILABLE_PATH = '/home/contests';
// export const CONTESTS_AVAILABLE_PATH = '/available';

// export const CONTESTS_PATH = '/contests';
export const CONTEST_PATH = '/contests/:id';
export const MAKE_PICKS_PATH = '/contests/:id/make_picks/week-:weekOrder';
export const SELECTED_PLAYERS_PATH = '/contests/:id/selected_players/week-:weekOrder/category-:categoryId';

export const CONTEST_CATEGORY_PATH = '/contests/:id/make_picks/week-:weekOrder/category-:categoryId';

export const CONTEST_PAYMENT_SUCCESS_PATH = '/contests/:id/on_payment_success';
export const CONTEST_PAYMENT_FAILURE_PATH = '/contests/:id/on_payment_failure';
export const CONTEST_PAYMENT_PENDING_PATH = '/contests/:contestId/payment/status';
export const CONTEST_PAY_PATH = '/contests/:id/pay/week-:weekOrder/category-:categoryId';

export const CONTEST_BREAKDOWN_CATEGORY_PATH = '/contests/:id/game_stats/pick_breakdown/category-:categoryId';
export const CONTEST_BREAKDOWN_PATH = '/contests/:id/game_stats/pick_breakdown';
export const CONTEST_LEADERBOARD_PATH = '/contests/:id/game_stats/leaderboard';
export const CONTEST_LEADER_PARTICIPATIONS_PATH = '/contests/:id/game_stats/leaderboard/leader_participations';
export const CONTEST_LEADER_DIAGRAM_STATS_PATH = '/contests/:id/game_stats/leaderboard/diagram_stats';
export const CONTEST_BREAKDOWN_OVERALL_PATH = '/contests/:id/game_stats/pick_breakdown/overall_pick_breakdown';
export const CONTEST_BREAKDOWN_WEEK_PICK_PATH = '/contests/:id/game_stats/pick_breakdown/week_pick_breakdown';
export const CONTEST_SUCCES_PLAYER = '/contests/:contestId/players/:playerId/:categoryId';

export const HOW_TO_PLAY_KIND = '/how_to_play/:kind';

export const HOW_TO_PLAY = '/how_to_play';

export const PRIZES = '/prizes';
export const PRIZES_PATH = '/prizes';
export const CONTACT_US_PATH = '/contact_us';
export const FAQ_PATH = '/faq';
export const FAQ = '/faq';
export const PROMO_PATH = '/promo/:id';
export const CONTACT_US = '/contact_us';

export const TERMS = '/terms';
export const PRIVACY = '/privacy';

export const getPath = curry((path, params) => {
  let result = path;
  Object.keys(params).forEach((key) => {
    result = result.replace(`:${key}`, params[key]);
  });
  return result;
});
