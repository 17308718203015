// // @flow

// import React from 'react';
// import filterLogo from 'v2/components/shared/Image/filter.svg';

// import Button from '../Button';

// function ContestsFilter() {
//   return <Button logo={filterLogo} alt="Filter" handleClick={() => console.log('ContestsFilter')} />;
// }

// export default ContestsFilter;
