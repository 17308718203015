/* eslint-disable react/prop-types */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import { parseISO, differenceInHours } from 'date-fns/fp';
import { connect } from 'react-redux';
import { compose as recompose } from 'recompose';

import { getAvailableContestsList } from 'v2/selectors/contests';
import { getMyGamesActiveList } from 'v2/selectors/myGamesActive';
import { isDefined } from 'v2/helpers/ramda';

import Games from './Games';
import NavigationBlock from './NavigationBlock';
import * as paramsHelpers from './NavigationBlock/helpers/params';

const propTypes = {};

const defaultProps = {};

const leaders = [
  { username: 'NAME OF THE USERNAME HERE', streak: 12, longest: 34 },
  { username: 'NAME OF THE USERNAME HERE', streak: 11, longest: 32 },
  { username: 'NAME OF THE USERNAME HERE', streak: 9, longest: 20 },
  { username: 'NAME OF THE USERNAME HERE', streak: 8, longest: 19 },
  { username: 'NAME OF THE USERNAME HERE', streak: 8, longest: 18 },
  { username: 'NAME OF THE USERNAME HERE', streak: 7, longest: 17 },
  { username: 'NAME OF THE USERNAME HERE', streak: 6, longest: 16 },
  { username: 'NAME OF THE USERNAME HERE', streak: 5, longest: 15 }
];

const filterBySubmitPicks = (contests) =>
  contests.filter(({ totalSelectionsRequired, submitPicks }) => totalSelectionsRequired !== submitPicks);

const filterByLeague = (league) => (contests) =>
  contests.filter((contest) => {
    if (league) {
      return contest.league.toLowerCase() === league.toLowerCase();
    }

    return true;
  });

const filterByKind = (kind) => (contests) =>
  contests.filter((contest) => {
    if (kind) {
      return contest.kind.toLowerCase() === kind.toLowerCase();
    }

    return true;
  });

const selectNotLiveContests = R.filter(R.compose(R.not, R.prop('isContestLive')));
// const selectWhereNotLastDay = R.filter(
//   R.ifElse(
//     R.compose(R.equals('weekly'), R.prop('kind')),
//     R.compose(R.lt(24), differenceInHours(new Date()), parseISO, R.prop('endsAt')),
//     R.always(true),
//   ),
// );

const { kindKey, leagueKey, activeIdKey, defaultLeagueKey, defaultKindKey } = paramsHelpers;

const getLens = (key) => R.lens(R.prop(key), R.assoc(key));
const setParam = R.curry((params, lensKey, key) => R.set(getLens(lensKey), key, params));
const getParam = R.curry((params, lensKey) => R.view(getLens(lensKey), params));

const filterContests = (params) =>
  R.compose(
    filterByKind(R.propOr(defaultKindKey, kindKey, params)),
    filterByLeague(R.propOr(defaultLeagueKey, leagueKey, params)),
  );

function AvailableGames({ availableContests, myGamesActive, session }) {
  const [params, setParams] = useState({});

  const filterByParams = filterContests(params);

  function onParamsChange(input) {
    paramsHelpers.stringifyParams(input);
  }

  const setFilterParam = R.compose(setParams, R.tap(onParamsChange), setParam(params));

  const onChange = R.curry((lensKey, key) => {
    setFilterParam(lensKey, key);
  });

  useEffect(() => {
    R.compose(
      R.when(isDefined, R.compose(setParams, R.tap(onParamsChange), R.pick([kindKey, leagueKey]))),
      R.head,
      R.sortWith([
        R.descend(R.propEq('kind', 'survive')),
      ]),
    )(availableContests);
  }, [availableContests]);



  return (
    <StyledAvailableGames>
      <NavigationBlock onChange={onChange} getStatus={getParam(params)} />
      {/* <Games
        session={session}
        gamesCollection={R.compose(selectNotLiveContests, filterByParams)(availableContests)}
        myGamesCollection={myGamesActive}
        kind={R.propOr(defaultKindKey, kindKey, params)}
        league={R.propOr(defaultLeagueKey, leagueKey, params)}
        showHeadshots={false}
        showFAQ
      /> */}
      <LeaderboardContainer>
        <HeaderRow>
          <HeaderItem>Username</HeaderItem>
          <HeaderItem>Streak</HeaderItem>
          <HeaderItem>Longest</HeaderItem>
        </HeaderRow>

        {leaders.map((leader, index) => (
          <LeaderboardRow key={index} rank={index + 1}>
            <Rank rank={index + 1}>{index + 1}</Rank>
            <Username>{leader.username}</Username>
            <Streak>{leader.streak}</Streak>
            <Longest>{leader.longest}</Longest>
          </LeaderboardRow>
        ))}
      </LeaderboardContainer>
    </StyledAvailableGames>
  );
}

const StyledAvailableGames = styled.div``;

const LeaderboardContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  background-color: #1e1e1e;
  border-radius: 10px;
  overflow: hidden;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
`;

const HeaderItem = styled.div`
  flex: 1;
  text-align: center;
`;

const LeaderboardRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  margin: 12px 0;
  background-color: ${(props) => (props.rank <= 3 ? '#333' : '#2b2b2b')};
  border: ${(props) => (props.rank <= 3 ? '2px solid #E70E50' : 'none')};
  border-radius: 8px;
  font-size: 14px;
  height: 52px;
`;

const Rank = styled.div`
  background-color: ${(props) => (props.rank <= 3 ? '#E70E50' : '#444')};
  color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
`;

const Username = styled.div`
  flex: 1;
  color: #fff;
  text-align: center;
`;

const Streak = styled.div`
  color: #fff;
  text-align: center;
`;

const Longest = styled.div`
  color: #fff;
  text-align: center;
`;


AvailableGames.propTypes = propTypes;
AvailableGames.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  availableContests: getAvailableContestsList(state),
  myGamesActive: getMyGamesActiveList(state),
});

const enhance = recompose(React.memo, connect(mapStateToProps));

export default enhance(AvailableGames);
