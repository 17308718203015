import React, { useState, useEffect } from 'react';
import { not, contains } from 'ramda';
import { Switch, Route, Redirect } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { camelizeKeys } from 'v2/utils/humps';
import { history } from 'v2/stores/configureStore';

import { getCookieByName, setCookieByName } from 'v2/utils/cookies';
import withIEModalWarning from 'v2/hocs/withIEModalWarning';

import { isAuthorized, isUserStatusAcceptedTermsOfService, getUser } from 'v2/selectors/session';
import {
  ROOT_PATH,
  MY_GAMES,
  MY_GAMES_LEAGUE_PATH,
  CONTEST_PATH,
  ACCOUNT_PATH,
  AUTH_PATH,
  LEAGUE_PATH,
  HOW_TO_PLAY,
  PROMO_PATH,
  PRIZES_PATH,
  FAQ_PATH,
  CONTACT_US_PATH,
  BLOG_PATH,
  BLOG_CATEGORY_PATH,
  BLOG_CATEGORIES_PATH,
  BLOG_ARTICLE_PATH,
  CONTEST_PAYMENT_PENDING_PATH,
} from 'v2/constants/router';

import background from '../shared/Image/bg.png';
import background1 from 'v2/components/shared/Image/background_1.png';
import PaymentScreen from 'v2/components/Contests/PaymentScreen';

import ContestRoutes from './contest';

import AuthPage from '../AuthPage';
import PromoPages from '../PromoPages';
import HomePage from '../HomePage';
import AccountPage from '../AccountPage';
import PrizesPage from '../PrizesPage';
import FAQsPage from '../FAQsPage';
import ContactUsPage from '../ContactUsPage';
// import RestorePassword from '../RestorePassword';
import MyGames from '../MyGames';
import Header from '../Header';
import Footer from '../Footer';
import HowToPlay from '../HowToPlay';
import HowToPlayModal from './HowToPlayModal';
import ContestDescriptionModal from './ContestDescriptionModal';
import BlogRoutes from '../Blog';

import Menu from '../Menu';

import PrivateRoute from './privateRoute';
import LeaderboardPage from '../LeaderboardPage';
import { LEADERBOARD_PATH } from '../../constants/router';

// const getDom = (str) => {
//   const res = str.match(/\/\w+/);
//   if (res) return res[0];
//   return str;
// };

function RootRoutes({ currentUser, isAuthorized, isUserStatusAcceptedTermsOfService, ...props }) {
  const [isActiveMenu, setActiveMenu] = useState(false);

  const hideMenu = () => setActiveMenu(false);

  useEffect(() => {
    document.body.style.overflow = isActiveMenu ? 'hidden' : '';
  }, [isActiveMenu]);

  useEffect(() => {
    const { loginCount } = camelizeKeys(currentUser);
    if (isAuthorized && loginCount <= 1 && getCookieByName('isShowedTutorial') == 'false') {
      setCookieByName('isShowedTutorial', 'true');
      history.push(HOW_TO_PLAY);
    }
  }, []);

  return (
    <GlobalContainer>
      <Content>
        <Header session={props.session} setMenu={setActiveMenu} isActiveMenu={isActiveMenu} />
        <Layout>
          {isActiveMenu && (
            <MenuContainer>
              <Menu close={hideMenu} />
            </MenuContainer>
          )}
          <Switch>
            {/* <Route path={ROOT_PATH} render={(routerProps) => <HomePage {...props} {...routerProps} />} exact /> */}

            <Route
              path={[ROOT_PATH, LEAGUE_PATH]}
              component={(routerProps) => <HomePage {...props} {...routerProps} />}
              exact
            />

            <Route
             path={LEADERBOARD_PATH}
             component={(routerProps) => <LeaderboardPage {...props} {...routerProps} />}
             exact
           />

            <Route path={BLOG_ARTICLE_PATH} component={(routerProps) => <BlogRoutes {...props} {...routerProps} />} />
            <Route path={BLOG_PATH} component={(routerProps) => <BlogRoutes {...props} {...routerProps} />} />

            <Route
              path={[MY_GAMES, MY_GAMES_LEAGUE_PATH]}
              render={(routerProps) => <MyGames {...props} {...routerProps} myGamesLink />}
              exact
            />
          <Route
            path={CONTEST_PAYMENT_PENDING_PATH}
            render={(routerProps) => <PaymentScreen {...props} {...routerProps} />}
            exact
          />

            <PrivateRoute
              path={ACCOUNT_PATH}
              component={(routerProps) => <AccountPage {...props} {...routerProps} />}
            />

            <Route path={HOW_TO_PLAY} component={(routerProps) => <HowToPlay {...routerProps} />} />

            <Route path={PROMO_PATH} component={(routerProps) => <PromoPages {...routerProps} />} />

            <Route path={PRIZES_PATH} component={(routerProps) => <PrizesPage {...routerProps} />} />

            <Route path={FAQ_PATH} component={(routerProps) => <FAQsPage {...routerProps} />} />

            <Route path={CONTACT_US_PATH} component={(routerProps) => <ContactUsPage {...routerProps} />} />

            <PrivateRoute
              path={CONTEST_PATH}
              component={(routerProps) => <ContestRoutes {...props} {...routerProps} />}
            />

            <Route
              path={AUTH_PATH}
              render={(routerProps) =>
                isAuthorized && isUserStatusAcceptedTermsOfService ? (
                  <Redirect
                    to={{
                      pathname: ROOT_PATH,
                      state: { from: routerProps.location },
                    }}
                  />
                ) : (
                  <AuthPage {...props} {...routerProps} />
                )
              }
            />
          </Switch>
        </Layout>
      </Content>
      <Footer />
    </GlobalContainer>
  );
}

const Layout = styled.div`
  padding-top: 60px;
  @media (max-width: 768px) {
    /* padding-top: 45px; */
    margin-bottom: -30px;
    background-image: none;
  }
  /* @media (max-width: 425px) {
    margin-top: 55px;
  } */
`;

const MenuContainer = styled.div`
  position: absolute;
  top: 45px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #131317;
  z-index: 9;
`;

const Content = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
`;

const GlobalContainer = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: column;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-position: top;
  /* background-position-y: 50px; */

  &:after {
    content: '';
    display: ${({ showBackgroundImg }) => (showBackgroundImg ? 'block' : 'none')};
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url(${background1});
    background-repeat: no-repeat;
    background-position: bottom;
    z-index: -1;
    position: absolute;
    background-size: contain;
  }

  @media (max-width: 768px) {
    &:after {
      background-size: cover;
    }
  }
`;

const mapStateToProps = (state) => ({
  isAuthorized: isAuthorized(state),
  isUserStatusAcceptedTermsOfService: isUserStatusAcceptedTermsOfService(state),
  currentUser: getUser(state),
});

const enhance = compose(
  withIEModalWarning,
  ContestDescriptionModal.HOCWrapper(ContestDescriptionModal),
  HowToPlayModal.HOCWrapper(HowToPlayModal),
  hot,
  connect(mapStateToProps),
);

export default enhance(RootRoutes);
