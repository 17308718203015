import pickemLogo from 'v2/components/shared/Image/kind_icons/new_pickem.svg';
import survivorLogo from 'v2/components/shared/Image/kind_icons/new_survivor.svg';
import allKindLogo from 'v2/components/shared/Image/kind_icons/all_red.svg';
import streakLogo from 'v2/components/shared/Image/kind_icons/streak.svg';
// import bracketLogo from 'v2/components/shared/Image/kind_icons/bracket.svg';
// import streakLogo from 'v2/components/shared/Image/kind_icons/streak.svg';

export default [
  ['survive', { label: 'survivor', img: survivorLogo, activeColor: 'purpleAccent' }],
  ['weekly', { label: 'pickem', img: pickemLogo, activeColor: 'greenAccent' }],
  ['streak', { label: 'streak', img: streakLogo, activeColor: 'redAccent' }]
  // ['survive', { label: 'survivor', img: survivorLogo, activeColor: 'purpleAccent' }],
  // ['weekly', { label: 'pickem', img: pickemLogo, activeColor: 'greenAccent' }],
  // ['bracket', { label: 'bracket', img: bracketLogo, survivorLogo, activeColor: 'purpleAccent' }],
  // ['streakLogo', { label: 'streakLogo', img: streakLogo, survivorLogo, activeColor: 'purpleAccent' }],
];

export const KIND_TABS = [
  ['all', { label: 'all', img: allKindLogo, activeColor: 'redAccent' }],
  ['survive', { label: 'survivor', img: survivorLogo, activeColor: 'purpleAccent' }],
  ['weekly', { label: 'pickem', img: pickemLogo, activeColor: 'greenAccent' }],
];
