import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import * as requestTypes from 'v2/constants/requestTypes';
import * as actions from 'v2/actions';
import useWindowSize from 'v2/services/useWindowSize';
import { InstagramCarousel, PlayerQuote } from 'v2/components/shared';

import {
  getDecoratedRefsMedia,
  getDecoratedRefsWinners,
  getRefsOffers,
  getRefsMainContestId,
} from '../../selectors/refs';

import { getSignOut } from '../../selectors/signOut';

import { requestInProcess } from '../../selectors/request';

import { AvailableGames } from './shared';

const propTypes = {
  loadingAvailableContests: PropTypes.bool,
  loadingRefs: PropTypes.bool,
};

const defaultProps = {
  loadingAvailableContests: false,
  loadingRefs: false,
  specialOffers: [],
};

function LeaderboardPage(props) {
  const { session, specialOffers } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <StyledPanel>
      {/* {loadingRefs ? <img alt="" src={loader} /> : null} */}
      <MainContentWrapper>
        <Container id="AvailableGames">
          <AvailableGames session={session} />
        </Container>
      </MainContentWrapper>

      {/* <ContainerSection>
        <PrizeSlider />
      </ContainerSection>
      <Container>

        <ContainerSection style={{ marginTop: '35px' }} noPadding>
          {specialOffers.length > 0 && <Offers offers={specialOffers} />}
        </ContainerSection>

      </Container>

      <Container>
        <ContainerSection>
          <PlayNow />
        </ContainerSection>
      </Container> */}
    </StyledPanel>
  );
}

const mapStateToProps = (state) => ({
  loadingAvailableContests: requestInProcess(state, { requestType: requestTypes.AVALAIBLE_CONTESTS_FETCH }),
  media: getDecoratedRefsMedia(state),
  winners: getDecoratedRefsWinners(state),
  offerts: getRefsOffers(state),
  mainContestId: getRefsMainContestId(state),
  loadingRefs: requestInProcess(state, { requestType: requestTypes.REFS_HOME_PAGE_FETCH }),
  signOut: getSignOut(state),
  specialOffers: getRefsOffers(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchAvailableContests: () => dispatch(actions.fetchAvailableContests()),
  fetchHomePageRefs: () => dispatch(actions.fetchHomePageRefs()),
  fetchMyGamesActive: () => dispatch(actions.fetchMyGamesActive()),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { fetchAvailableContests, fetchHomePageRefs, fetchMyGamesActive } = this.props;
      fetchHomePageRefs();
      fetchAvailableContests();
      fetchMyGamesActive();
    },
  }),
);

const StyledPanel = styled.div`
  overflow: visible;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

const MainContentWrapper = styled.div`
  margin-bottom: 25px;

  /* @media (max-width: 425px) {
    background-image: linear-gradient(to bottom, #131317, #151519, #17171c, #19191e, #1b1b21);
  } */
`;

const ContainerSection = styled.div`
  margin-bottom: 80px;
  padding: ${({ noPadding }) => (noPadding ? '0' : '0 5px')};
  overflow: hidden;

  @media (max-width: 425px) {
    margin-bottom: 20px;
  }
`;

const InstagramWrapper = styled.div`
  margin-top: 25px;
`;

const Container = styled.div`
  width: 100%;
  position: relative;
  max-width: 1110px;
  /* padding: 0px 15px; */
  margin: 0 auto;
  overflow: visible;
  flex: 1 0 auto;
  box-sizing: border-box;
  /* margin-bottom: 30px; */

  @media (max-width: 768px) {
    /* margin-bottom: 15px; */
  }

  @media (max-width: 425px) {
    padding: 0;
  }
`;

// @media (max-width: 768px) {
//   flex-direction: column;
// }

LeaderboardPage.propTypes = propTypes;
LeaderboardPage.defaultProps = defaultProps;

// export default HomePage;
export default enhance(LeaderboardPage);
