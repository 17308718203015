import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

import { ConnectedRouter } from 'connected-react-router';
import { normalize } from 'styled-normalize';

// import { ToastsContainer } from 'v2/components/PushToasts';
import * as actions from 'v2/actions';
import theme from 'v2/constants/UI/theme';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { configureStore, history } from '../stores/configureStore';
import RootRoutes from './Routes';

function App(props) {
  const { state, refs } = props;
  const store = configureStore(state);

  useEffect(() => {
    async function fetchMyAccount() {
      store.dispatch(actions.fetchMyAccount());
    }
    fetchMyAccount();
  }, []);

  return (
    <Provider store={store}>
      <GlobalStyle />
      <GoogleOAuthProvider clientId="381697973998-43pc4upm6m8gt8n8iq30pqkaalf9dr39.apps.googleusercontent.com">
        <ThemeProvider theme={theme}>
          <ConnectedRouter history={history}>
            <RootRoutes refs={refs} session={state.session} />
            {/* <ToastsContainer /> */}
          </ConnectedRouter>
        </ThemeProvider>
      </GoogleOAuthProvider>
    </Provider>
  );
}

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  ${normalize}
  html {
    height: 100%;
  }
  body {
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
    height: 100%;
    display: flex;
    min-height: 100%;
    flex-direction: column;
    background-color: #131317;
    background-image: none;
    box-sizing: border-box;
    color: #fff;
    font-family: 'Roboto Condensed', 'Roboto';
    -webkit-font-smoothing: antialiased;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
  }
  a {
    text-transform: uppercase;
    text-decoration: none;
    color: #ffffff;
    &:hover{
      text-decoration: underline;
    }
  }
  h1 {
    font-size: 22px;
    line-height: 28px;
    color: #fff;
    @media (min-width: 400px) {
      font-size: 30px;
      line-height: 38px;
    }
    @media (min-width: 768px) {
      font-size: 44px;
      line-height: 46px;
    }
  }
  h2 {
    font-size: 30px;
    line-height: 38px;
    color: #fff;
    @media (min-width: 768px) {
      font-size: 30px;
      line-height: 38px;
    }
  }
  h3 {
    color: #FFFFFF;
    margin: 5px 0 0;
    line-height: 28px;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 24px;
    text-transform: uppercase;
  }
  h4 {
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    margin: 0;
  }
  p {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.5px;
    color: #656579;
    margin: 5px 0;
  }
  span {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.5px;
    color: #656579;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
  }
  ul {
    list-style: none;
    padding: 0;
  }

  ${'' /* body {
    overflow:hidden
  } */}

  .item_top_left {
    border-top: none;
    border-left: none;
    @media (min-width: 768px) {
      top: 0;
      left: 0;
    }
  }

  .remove_accent {
    backdrop-filter: blur(3px);
    filter: brightness(0.85) blur(1px);
    opacity: 0.5;
  }

  .slick-extra-wrapper {
    height: 100%;
    width: 100%;
  }

  .inactive {
    pointer-events: none;
    overflow: hidden !important;

    ${
      '' /* &:after {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 999;
    } */
    }
  }

  .item_top_right {
    border-top: none;
    border-right: none;
    @media (min-width: 768px) {
      top: 0;
      right: 0;
    }
  }
  .item_bottom_left {
    border-bottom: none;
    border-left: none;
    @media (min-width: 768px) {
      bottom: 0;
      left: 0;
    }
  }
  .item_bottom_right {
    border-bottom: none;
    border-right: none;
    @media (min-width: 768px) {
      bottom: 0;
      right: 0;
    }
  }
  .slick-list {
    min-height: fit-content;
    @media (min-width: 768px) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  .preview__img .slick-list {
    min-height: fit-content;
    @media (min-width: 768px) {
      margin-left: -25px;
      margin-right: -25px;
    }
  }

  .slick-dots{
    position: initial;
    display: flex !important;
    padding: 0px 10px;
    box-sizing: border-box;
  }
  .slick-dots li {
    width: 100%;
    height: 3px;
    margin: 0 5px;
  }
  .slick-dots li button {
    width: 100%;
    height: 4px;
    padding: 0;
    outline: none;
    background: #26262E;
    border-radius: 2px;
  }
  .slick-dots li.slick-active button {
    background: #FFFFFF;
  }

  .slick-dots li button:before {
    display: none;
  }

  .slick-slider .slider_wrap{
    position: relative;
    padding-right: 5px;
    padding-left: 5px;
    box-sizing: border-box;
    @media (min-width: 768px) {
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  .preview__img img {
    object-fit: contain;
  }

  .show_drawer {
    display: block;
    transition: opacity 0.15s ease-in-out;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
    transition: top 0.1s ease, transform 0.2s 0.2s ease;
    z-index: 1000000;
    cursor: pointer;
    opacity: 1;
    animation-timing-function: ease-out;
    animation-duration: 0.3s;
    animation-name: modal-video;
    transition: opacity 0.3s ease-out;
    height: 100vh;
    @media (min-width: 768px) {
      background-color: transparent;
      height: auto;
    }
  }

  .show_dropdown {
    display: block;
    transition: opacity 0.15s ease-in-out;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
    transition: top 0.1s ease, transform 0.2s 0.2s ease;
    z-index: 1000000;
    cursor: pointer;
    opacity: 1;
    animation-timing-function: ease-out;
    animation-duration: 0.3s;
    animation-name: modal-video;
    transition: opacity 0.3s ease-out;
    height: 100vh;
    @media (min-width: 768px) {
      height: auto;
      background-color: transparent;
    }
  }


  .toggle_menu-open {
    background-color: rgba(255, 255, 255, 0);
    &:before {
      bottom: 0;
      transform: rotate(-45deg);
      transition: bottom 0.1s ease, transform 0.2s 0.2s ease;
      background: #fff;
    }
    &:after {
      top: 0;
      transform: rotate(45deg);
      transition: top 0.1s ease, transform 0.2s 0.2s ease;
      background: #fff;
    }
  }

  .toggle_dropdown-open {
    transform: rotate(180deg);
    transition: top 0.1s ease, transform 0.2s 0.2s ease;
  }

  .game_active {
    position: relative;
    color: #fff;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -10px;
      width: 100%;
      height: 4px;
      background: #5d5ce2;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }

  .body_overflow_hidden{
    overflow: hidden;
    @media (min-width: 768px) {
      overflow: visible;
    }
  }

  .body_overflow_show{
    overflow: visible;
    @media (min-width: 768px) {
      overflow: visible;
    }
  }

  .img_filter {
    margin-bottom: 10px;
    @media (min-width: 768px) {
      width: 27px;
    }
  }

  .img_filter_weekly {
    width: 40px;
    margin-bottom: 10px;
    @media (min-width: 768px) {
      width: 27px;
    }
  }
  .img_filter_survive {
    width: 57px;
    margin-bottom: 10px;
    @media (min-width: 768px) {
      width: 27px;
    }
  }

  .icon_type_game {
    position: absolute;
    top: 0;
    left: 0;
    padding: 2px 4px;
    background: red;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    border-radius: 2px;
    min-width: 70px;
    text-align: center;
    letter-spacing: 0.5px;
  }

  .icon_type_game--recent {
    left: auto;
    right: 0;
    background: #26262e;
    color: #656579;
  }

  .survivour_type {
    background: #5D5CE2;
  }

  .weekly_type {
    background: #0BD855;
  }

  ${
    '' /* .promo_slider .slick-prev, .slick-next {
    display: block;
    background-color: #26262E;
    width: 44px;
    height: 44px;
    border-radius: 44px;
  }

  .promo_slider .slick-prev {
    left: 15%;
    z-index: 10;
    &:before {
      content: url(${arrowPrev});
      font-size: 15px;
      line-height: 2;
    }
  }

  .promo_slider .slick-next {
    right: 15%;
    z-index: 10;
    &:before {
      content: url(${arrowNext});
      font-size: 15px;
      line-height: 2;
    }
  }

  .promo_slider .slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
    color: transparent;
    outline: none;
    background: #303038;
  } */
  }

  .arrow_open #arrow {
    fill: #fff;
    transition: fill 0.5s;
  }

  .arrow_open .rotate {
    transform: rotate(180deg);
    transition: fill 1s;
  }

  .white  {
    color: #fff;
  }

  .wrapper:hover #arrow {
    fill: #fff;
  }

  .wrapper:hover .white {
    fill: #fff;
  }

.link {
  font-weight: 700;
  text-transform: uppercase;
  &:hover {
    text-decoration: none;
  }
}

.link__pick {
  display: block;
  padding: 15px 0;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background: #26262e;
  &:hover {
    background: #33333e;
  }
}

.link__dashboard {
  color: #656579;
}

.link__survive {
  color: #0BD855;
}

.link__weekly {
  color: #5D5CE2;
}

.preview_wrap {
  position: relative;
  max-width: 730px;
  margin: 0 auto;
}

  *:focus {
    outline: 0;
  }

.success {
  color: #0BD855;
}

.danger {
  color: #D0021B;
}

${'' /* game indicator */}
.join {
  background: #7ED321;
}
.eliminate {
  background: #D0021B;
}

.participate {
  background: #F5A623;
}

${'' /* checkbox */}

${
  '' /* label {
  width: 32px;
  height: 16px;
  background: #5D5CE2;
  border-radius: 12px;
  box-shadow: 0px 50px 20px 0 rgba(0,0,0,0.1);
  display: flex;
  position: relative;
  transform: scale(2);
  transition: transform 300ms ease,box-shadow 300ms ease;
  cursor: pointer;
} */
}

${
  '' /* input {
  display: none;
}

label:after {
  width: 14px;
  height: 14px;
  animation: move-left 400ms;
  background: #E2E2E2 no-repeat center;
  border-radius: 12px;
  content: '';
  top: 1px;
  left: 17px;
  outline: none;
  position: absolute;
  transition: background 100ms linear;
}

label:active {
  box-shadow: 0px 10px 20px 0 rgba(0,0,0,0.2);
  transform: scale(1.8);
} */
}

${
  '' /* input:checked + label:after {
  animation: move-right 400ms;
  background: #656579 no-repeat center;
  left: 1px;
}

input:checked + .toogle--off{
  background: #26262E;
} */
}



`;

export default App;
