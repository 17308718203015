import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { SIGN_UP_PATH, AUTH_VIA_FACEBOOK } from 'v2/constants/router';
import { GoogleLogin } from '@react-oauth/google';

function SocialProposal({ location: { pathname } }) {
  const handleLoginSuccess = (credentialResponse) => {
    const idToken = credentialResponse.credential; // This is the Google ID token

    // Send the ID token to your Rails backend for verification
    fetch('https://gonestreakin.foam.dev/auth/google', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: idToken }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.token) {
          console.log('User authenticated successfully:', data);
          // Optionally store the returned token in local storage or state for further requests
          // eslint-disable-next-line no-undef
          localStorage.setItem('google-authToken', data.token);
        } else {
          console.log('Authentication failed:', data);
        }
      })
      .catch((error) => {
        console.error('Error during Google authentication:', error);
      });
  };

  const handleLoginError = () => {
    console.error('Login failed');
  };

  return (
    <SocialProposalContainer>
      <SocialProposalTutle>
        {`Sign ${pathname == SIGN_UP_PATH ? 'up' : 'in'} using social media account`}
      </SocialProposalTutle>
      <FaceBookButton href={AUTH_VIA_FACEBOOK}>CONTINUE WITH FACEBOOK</FaceBookButton>
      <GoogleLogin onSuccess={handleLoginSuccess} onError={handleLoginError} />
      <LabelOr>Or</LabelOr>
    </SocialProposalContainer>
  );
}

const SocialProposalTutle = styled.span`
  text-transform: capitalize !important;
  font-size: 17px;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 25px;

  @media (max-width: 475px) {
    line-height: 1.4em;
  }
`;

const FaceBookButton = styled.a`
  position: relative;
  background: #3b5998;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0.5px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  transition: filter 0.2s linear;
  width: 100%;

  font-weight: bold;
  padding: 1em;
  border-radius: 4px;

  &:hover {
    filter: brightness(105%);
  }
`;

const LabelOr = styled.span`
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
`;

const SocialProposalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default withRouter(SocialProposal);
